<template>
    <div>
        <div v-if="item != null" style="font-size:12px;">
            <div class="wt-5 float-l">
                <strong>{{ index + 1 }}.</strong>
            </div>
            <div class="wt-24 float-l">{{ item.product.name }} <br>
                <!-- <button class="btn btn-primary" style="width: 40%;" @click="addserial()"> Serial No./IMEI</button> -->
                <!-- <label class="control-label">Serial No.</label> -->
            </div>
            <div class="wt-12 float-l text-center">
                {{ sp > 0 ?parseFloat(sp).toFixed(2) : 0 }}
                <!-- <input class="form-control form-cascade-control input-small" type="number"
                    style="height:24px;width:100px;padding-right: 0;" v-model="sp" /> -->
            </div>
            <div class="wt-12 float-l text-c text-center">
                {{ this.item.quantity }}
                <!-- <input class="form-control form-cascade-control input-small" type="number"
                    style="height:24px;width:100px;padding-right:0;" v-model="quantity" /> -->
            </div>
            <!-- <div><button class="btn btn-outline-info" @click="addbatchcodemodal(item.id)"> Add Batchcode & Expiary  </button>
                <batch-code-for-item :itemid ="item.id"></batch-code-for-item>
            </div>-->

            <div class="wt-12 float-l text-center">
                <i class="bx bx-rupee"></i>
                {{ item.netpricewithouttax }}
            </div>
            <div class="wt-15 float-l text-center" style="width: 15%;height: 37px;">
                <div v-if="item.collectedtaxes">
                    <div v-for="(taxitem) in item.collectedtaxes" v-bind:key="taxitem.id">
                        <!-- {{ getMasterLabelByType(taxitem.labelid, MASTER_TAXES) }} -->
                        <cart-item-tax :productid="item.product.id" :taxlabelid="taxitem.labelid"
                            :cartitemindex="index"></cart-item-tax>
                    </div>
                </div>
                <div v-else >
                    0
                </div>
            </div>
            <div class="wt-12 float-l text-center">
                <div class="qtycount">
                    <i class="bx bx-rupee"></i>
                    {{ item.finalamount }}
                </div>
            </div>
            <div class="wt-8 float-l text-center">
                <button type="button" class="btn btn-danger" @click="remove(item)">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="text-left" v-if="false">
                <button class="btn btn-primary" @click="addbarcodemodal()">Add Batchcode</button>
            </div>

            <div class="float-l col-md-12 " style="width:100%">
                <!-- <serial-no :quantity="quantity"></serial-no> -->
            </div>
            <div class="row" v-if="trantype==1">
                <!-- <div class="col-md-4 mt-10" v-for="(item, index) in item.barcodes" :key="index" style="position: relative;">
                    <input type="text" :value="item.barcode" class="form-control form-cascade-control input-small" disabled>
                    <span class="barcode-remove" @click="removeBarcode(item)">X</span>
                </div> -->
                <div class="col-md-4 mt-10" v-for="(barcode, index) in item.barcodes" :key="index" style="position: relative;">
                    <input v-if="barcode.barcode" type="text" :value="barcode.barcode" class="form-control form-cascade-control input-small" disabled>
                    <input v-else type="text" v-model="barcode.newValue" class="form-control form-cascade-control input-small" >
                    <span v-if="barcode.barcode" class="barcode-remove" @click="removeBarcode(index,barcode)"><i class='bx bx-x'></i></span>
                    <span v-else class="barcode-save" @click="saveNewBarcode(index,barcode)"><i class='bx bx-check-square'></i></span>
                </div>
            </div>
            <div v-if="trantype==2">
                <div class="col-md-4 mt-10" v-for="(item, index) in item.barcodes" :key="index" >
                    <input type="text" :value="item.barcode" class="form-control form-cascade-control input-small" disabled>
                    <!-- <span class="barcode-remove" @click="removeBarcode(item)">X</span> -->
                </div>
            </div>

            <!-- <div  class="row">
                <div class="col-md-2" v-for="(value,index) in item.barcodes.length" :key="value.id">
                    {{ value }}
                    <input type="text" class="form-control form-cascade-control" v-model="barcode[index]" style="height: 28px;margin-bottom: 10px;">
                </div>
                <div>
                    <button @click="savebarcode()" class="btn btn-secondary" v-if="!this.loading" style="line-height: 14px;margin: 0px 15px;">Save</button>
                    
                </div>
            </div> -->
            <!-- <div class="row">
                <div class="col-md-2" v-for="(item,index) in parseInt(quantity)" :key="item" :value="item.serialnumber" style="margin-bottom: 5px;">
                    <input class="form-control form-cascade-control" style="height: 28px;" v-model="serialnumber[index]"  >
                </div>
                <div>
                    <button @click="savebarcode(item)" class="btn btn-secondary" v-if="!this.loading" style="line-height: 14px;margin: 0px 15px;">Save</button>
                    
                </div>
            </div> -->
        </div>
        <!-- <div>
            <div v-if="show" style="width:100%">
                hello
            </div>
        </div> -->

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
// import SerialNo from './SerialNo.vue'
import Constants from '../../components/utilities/Constants.vue'
import CartItemTax from './CartItemTax.vue'
// import BarcodeItem from './BarcodeItem.vue'
//import BarCodeForItem from './BarCodeForItem.vue'
//import BatchCode from './BatchCode.vue'
export default {
    mixins: [Constants],
    props: ['index'],
    components: { CartItemTax },
    data() {
        return {
            show: false,
            serialnumber: [],
            saveBarcodes: [],
            loading: false,
            allserial: '',
            newbarcode: [],
            id: 0,
            barcode: [],
            param: { id: 0, quantity: 1, barcode: '' },
            sample: []
        }
    },

    computed: {
        ...mapGetters([
            'cartitems', 'selectedcustomer', 'selectedcustomeraddressid', 'selectedstoreid' ,'statecode', 'products',
             'applicabletaxes', 'barcodeitem', 'edititem', 'trantype'
        ]),
        item() {
            if (this.cartitems != null && this.cartitems.length > 0) {
                return this.cartitems[this.index]
            }
            return null
        },
        sp: {
            get() {
                return this.item.price
            },
            set(value) {
                //console.log("Changed")
                console.log("quantity",this.item.quantity, "value", value)
                this.recalculatealltaxes(this.item.quantity, value)
            }
        },
        spwt: {
            get() {
                return this.item.unitpricewithouttax
            },
            set(value) {
                //this.item.spwithouttax = value
                this.updatespwt(this.item.quantity, this.sp, value)
            }
        },
        quantity: {
            get() {
                return this.item.quantity
            },
            set(value) {
                // this.item.barcodes = []
                console.log("Changed2")
                console.log(value)
                this.recalculatealltaxes(value, this.sp)
                // this.getBarcode(value, this.param)
            }
        },

    },
    // watch: {
    //     quantity:function(){
    //         this.recalculatealltaxes(this.item.quantity, this.sp)

    //     }
    // },
    watch:{
		
    },
    mounted() {
        // if (this.item.quantity == 1) {
        //     this.item.barcodes.push(this.param)
        // }
        this.fetcheditbarcode();
        this.recalculatealltaxes(this.item.quantity, this.sp)

    },

    methods: {
       
        barcodeupdated(event, index) {
            console.log("Printing the event value")
            console.log(event)
            this.item.barcodes[index] = {
                id: 0,
                barcode: event?.barcode,
                colour: event?.colour,
                quantity: 1
            }
        },
        updateedititem(value) {
            //console.log("Inside updating edit item")
            this.$store.commit('assignedititem', value);
            console.log("", this.edititem)



        },
        recalculatealltaxes(quantity, sp, spwt) {
            //this.item.quantity = parseInt(obj.quantity)+parseInt(item.quantity);
            let collectedtaxes = this.item.collectedtaxes
            // console.log("Tax ",collectedtaxes)
            let netpricewithouttax = 0;
            let unitpricewithouttax = this.item.unitpricewithouttax;
            if (this.item.price !== sp) {
                console.log("inside price",this.item.price," sp ",sp)
                let totalpercent = 0;
                let self = this
                this.applicabletaxes.forEach(function (localitem) {

                    if (self.item != null && self.item.product.tax != null) {
                        let selectedtax = self.item.product.tax.find(block => block.labelid == localitem.labelid)
                        if (typeof selectedtax !== 'undefined') {

                            totalpercent = parseFloat(totalpercent) + parseFloat(selectedtax.value1)
                        }
                    }
                });
                unitpricewithouttax = parseFloat((parseFloat(sp)) / (1 + (totalpercent / 100))).toFixed(2)
                netpricewithouttax = parseFloat((parseFloat(sp * quantity)) / (1 + (totalpercent / 100))).toFixed(2)
                console.log("net peice without tax",netpricewithouttax)
                collectedtaxes.forEach(function (localitem) {
                    localitem.unitvalue = parseFloat((unitpricewithouttax * localitem.percent) / 100).toFixed(2)
                    localitem.value1 = parseFloat(localitem.unitvalue * quantity).toFixed(2)
                })

            }
            //  else if (this.item.quantity !== quantity) {
            //     netpricewithouttax = parseFloat(this.item.unitpricewithouttax * quantity).toFixed(2)
            //     collectedtaxes.forEach(function (localitem) {

            //         localitem.value1 = parseFloat(localitem.unitvalue * quantity).toFixed(2)
            //     })

            // }else if(this.item.quantity === quantity){
            //     netpricewithouttax = parseFloat(this.item.unitpricewithouttax * quantity).toFixed(2)
            //     collectedtaxes.forEach(function (localitem) {

            //         localitem.value1 = parseFloat(localitem.unitvalue * quantity).toFixed(2)
            //     })
            // }
            else if (this.item.spwithouttax !== spwt) {
                console.log("spwithouttax", this.item.spwithouttax)
            }
            if(quantity>0){
                netpricewithouttax = parseFloat(this.item.unitpricewithouttax * quantity).toFixed(2)
                collectedtaxes.forEach(function (localitem) {
                    localitem.value1 = parseFloat(localitem.unitvalue * quantity).toFixed(2)
                })
            }
            

            let netprice = parseFloat(sp * quantity).toFixed(2)
            let obj = {
                productid: this.item.product.id, tax: collectedtaxes, netprice: netprice, sp: sp, quantity: quantity,
                netpricewithouttax: netpricewithouttax, 
                unitpricewithouttax: unitpricewithouttax
            }

            //console.log("XXX")
            // console.log("object here", obj)
            this.$store.commit('updatecalculatedtax', obj)


        },
        updatespwt(quantity, sp, spwt) {
            let totalpercent = 0;
            let self = this
            let collectedtaxes = this.item.collectedtaxes
            let netpricewithouttax = 0;
            let unitpricewithouttax = this.item.unitpricewithouttax;
            this.applicabletaxes.forEach(function (localitem) {

                if (self.item != null && self.item.product.tax != null) {
                    let selectedtax = self.item.product.tax.find(block => block.labelid == localitem.labelid)
                    if (typeof selectedtax !== 'undefined') {

                        totalpercent = parseFloat(totalpercent) + parseFloat(selectedtax.value1)
                    }
                }
            });
            sp = parseFloat(spwt) + parseFloat(spwt * totalpercent / 100)
            console.log("Selling price:" + sp + ":" + spwt)
            unitpricewithouttax = spwt
            netpricewithouttax = spwt * quantity
            collectedtaxes.forEach(function (localitem) {
                localitem.unitvalue = parseFloat((unitpricewithouttax * localitem.percent) / 100).toFixed(2)
                localitem.value1 = parseFloat(localitem.unitvalue * quantity).toFixed(2)
            })
            //this.item.pricewithouttax = spwt
            let netprice = parseFloat(sp * quantity)
            let obj = {
                productid: this.item.product.id, tax: collectedtaxes, netprice: netprice, sp: sp, quantity: quantity,
                netpricewithouttax: netpricewithouttax, unitpricewithouttax: unitpricewithouttax
            }
            //console.log("XXX")
            console.log("updatespwt ",obj)
            this.$store.commit('updatecalculatedtax', obj)
        },
        remove(item) {
            this.$store.commit('removecartitem', item);
        },
        // addbarcodemodal() {
        //     let barcodeobject = { id: 0, localid: 0, productid: this.item.product.id, barcode: '', status: '', mfgdate: '', batchcode: '', expirydate: '' }

        //     this.$store.commit('assignedititem', this.item);
        //     this.$store.commit('assignbarcodeitem', barcodeobject);
        //     //this.showModal()
        //     this.$modal.show('addbatchcodemodal')
        //     this.$store.commit("assignshowbarcodemodal", true);
        // },
        savebarcode() {
            this.loading = true
            // console.log(this.item.barcodes)
            // for(let i=0;i<this.item.barcodes.length;i++){
            //     this.newbarcode[i] = {
            //         id: this.id,
            //         barcode:this.barcode[i],
            //         quantity:1
            //     }
            //     this.item.barcodes[i]=this.newbarcode[i]
            // }
            // console.log("Barcodes 2")
            // console.log(this.item.barcodes)
            console.log("bar code", this.item.barcodes)

        },
        getBarcode(quantity, param) {
            if (quantity > 0) {
                this.loading = false
                for (let i = 0; i < quantity; i++) {
                    this.item.barcodes.push(param)
                }
            } else {
                this.loading = true
                this.item.barcodes = []
            }
        },
        fetcheditbarcode() {
            // console.log("data here")
            let self = this
            if (this.cartitems != null) {
                this.cartitems.forEach((element) => {
                    if (element.productid == this.item.productid) {
                        // console.log("barcodes")
                        // console.log(element.barcodes)
                        element.barcodes?.forEach((value, index) => {
                            self.barcode[index] = value.barcode
                            // console.log("barcode here", self.barcode[index])
                        })
                    }
                })
            }
        },
        async removeBarcode(index,barcode) {
            if(this.trantype==1){
                if(barcode.id>0){
                    let param = {barcode: barcode.barcode}
                    const response =  await axios.post('api/sale/barcode/check',param)
                    if(response.data.status) {
                        this.$notify({text: 'Cannot remove barcode as it is used in sale',type: 'error'});
                        return;
                    }else {
                        this.$set(this.item.barcodes, index, { barcode: null, newValue: "", id:0 });
                    }
                }else{
                    this.$set(this.item.barcodes, index, { barcode: null, newValue: "",id:0 });
                }
            }else {
                this.$set(this.item.barcodes, index, { barcode: null, newValue: "",id:0 });
            }
            
            
            // Set barcode value to null and show input field
        },
        async saveNewBarcode(index,barcode) {
            
            let status = await this.checkDuplicateBarcode(barcode)
            if(status){
                this.$notify({ text: 'Duplicate barcode found.', type: 'error'});
            }else {
                this.$set(this.item.barcodes, index, { barcode: this.item.barcodes[index].newValue , id:0 });
            }
        },
        async checkDuplicateBarcode(barcode) {
            let status = false;
            let found = null;
            if(barcode.barcode==null){
                found = this.item.barcodes.find(o=>o.barcode==barcode.newValue )
            }else {
                found = this.item.barcodes.find(o=>o.barcode==barcode.barcode )
            }
            if(found !=  undefined){
                status = true;
            }else {
                let param = { barcode: barcode.barcode ?? barcode.newValue,storeid:this.selectedstoreid,trantype:this.trantype }
                await axios.post('api/check/duplicate/barcode',param)
                    .then(response=>{  status = response.data.status;  })
                    .catch(error=>console.log("error ",error))
            }
            return status
        }
    }
}
</script>
<style scoped>
.barcode-remove {
    position: absolute;
    right: 16px;
    bottom: 0px;
    color: #fff;
    background: #b72828;
    padding: 2px 3px 0px 4px;
    cursor: pointer;
    font-size: 20px;
}
.barcode-save {
    position: absolute;
    right: 16px;
    bottom: 0px;
    color: #fff;
    background: green;
    padding: 2px 3px 0px 4px;
    cursor: pointer;
    font-size: 20px;
}
</style>
    