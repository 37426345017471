<template>
<div class="col-md-12">
	<div class="row">
		<div class="col-md-12">
			<div class="row pt-10 pb-10" style="padding-right:16px">
				<div class="col-md-3 grd-bg-Dark" >
					<!-- <template v-if="trantype==1"><strong> Purchase</strong></template>
					<template v-if="trantype==2"><strong>Sale</strong></template> -->
					<!--<template v-if="trantype==5"><strong>Transfer Out</strong></template>
					<template v-if="trantype==6"><strong>Transfer In</strong></template>-->
				</div>
			<!--<div class="col-md-4" v-if="trantype==1" style="padding:0">
					<div class="col-md-5" style="padding-left:0">
						<input type="text" v-model="invoiceid" placeholder="Invoice .No" id="" class="form-control input-textbox">
					</div>
					<div class="col-md-7"  style="padding:0px;">
						<input type="date"  v-model="invoicedate" class="form-control input-textbox">
					</div>
				</div>-->	
				
			</div>
		</div>
	
			<div class="divTable">
             	<div class=" headRow">
					<div class="wt-5 float-l">Sr</div>
					<div class="wt-24 float-l">Product</div>
					<div class="wt-12 float-l">Price WT<br></div>
					<div class="wt-12 float-l" >Qty</div>
					<!-- <div >Batchcode <br> Expiary Date</div> -->
					<div class="wt-12 float-l" >Amount(Exl. Tax)</div>
					<div class="wt-15 float-l">Tax</div>
					<!-- <div class="wt-15 float-l">SGST</div> -->
					<div class="wt-12 float-l">Net Price</div>
					<div class="wt-8 float-l">Action</div>
				</div>
				<div class="divRow" style="border-bottom: 1px solid silver;" v-for="(item,index) in this.cartitems" v-bind:key="index">
					<cart-item :index="index" :item="item" @removeitem="removeitem(index)" ></cart-item>
				</div>
			</div>

			<div class="col-md-12" style="background-color: #b1d6f7;">
					<div class="text-right" style="padding-right:0;background-color:#b1d6f7;"><strong>Amount : </strong>{{amountwt}}</div>
				<div class="text-right" style="text-align:right;background-color: #b1d6f7;">
					<div v-for="item in hsnbasedtaxes" :key="item.id">
						<strong>Tax: </strong>
						{{ getMasterLabelByType(item.labelid,MASTER_TAXES)}}- : {{item.value1 }}
					</div>
				</div>
				<hr>
				<div class="row">
					<div class="col-md-6 text-right" style="text-align:right;background-color:#b1d6f7;"><strong>Total Qty: </strong>{{totalnetQty}}</div>
					<div class="col-md-6 text-right" style="text-align:right;background-color: #b1d6f7;"><strong>Total Amount: </strong>{{totalnetprice}}</div>
				</div>
			</div>
	</div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import CartItem from "./CartItem";
import Constants from '../../components/utilities/Constants.vue';
// import BarCode from './BarCode.vue';
// import BatchCode from './BatchCode.vue';
export default{
	mixins:[Constants],
	components:{ CartItem },
	props:['discount'],
	data(){
		return {
			counter:1
		}
	},
	mounted(){
		
	},
	computed:{
		invoiceid:{
			set:function(val){
				let obj = {...this.purchasedetails}
				obj.invoiceid = val
				this.$store.commit("assignpurchasedetails",obj)
				
			},
			get:function(){
				return this.purchasedetails.invoiceid
			}

		},
		invoicedate:{
			set:function(val){
				let obj = {...this.purchasedetails}
				obj.invoicedate = val
				this.$store.commit("assignpurchasedetails",obj)
			},
			get:function(){
				return this.purchasedetails.invoicedate
			}
		},
		totalnetprice(){
			let total = 0;
			this.$store.state.cartitems.forEach(function(item){
				total = parseFloat(item.finalamount) + parseFloat(total);
				total=total.toFixed(2);
			});
			// if(typeof this.discount!='undefined'){
			// 	total = total - this.discount??0
			// }
			return total ?? 0;
		},
		totalnetQty(){
			let totalqty = 0;
			this.$store.state.cartitems.forEach(function(item){
				totalqty = parseFloat(totalqty) + parseFloat(item.quantity)
			});
			return totalqty;
		},
		amountwt(){
			let amtwt = 0;
			this.$store.state.cartitems.forEach(function(item){
				amtwt = parseFloat(amtwt) + parseFloat(item.netpricewithouttax)
				amtwt=amtwt.toFixed(2)
			});
			return amtwt;
		},
		...mapGetters([
			'trantype','viewno','batchcode','addbarcode','barcodedetails','barcodeitem','barcodelist',
			'purchasedetails','cartitems','hsnbasedtaxes'
		]),
	},
	watch:{
		cartitems:function(){
			// console.log("cart item 1")
			// console.log(this.cartitems)
			if(this.cartitems!=null){
				let hsnbasedtaxes = [];
				this.cartitems.forEach(function(item){
					if(item.collectedtaxes!=null){
					item.collectedtaxes.forEach(function(taxitem){

						let found = hsnbasedtaxes.find(block=>{
							return block.labelid==taxitem.labelid && block.hsn==item.hsn
						})
						if(typeof found!=='undefined'){
							found.value1 = parseFloat(found.value1) + parseFloat(taxitem.value1)
							let foundIndex = hsnbasedtaxes.findIndex(block=>{
								return block.labelid==taxitem.labelid && block.hsn==item.hsn
							})
							found.value1 = found.value1.toFixed(2)
							hsnbasedtaxes.splice(foundIndex,1,found)
						} else {
							let obj = {hsn:item.hsn,labelid:taxitem.labelid,value1:taxitem.value1,percent:taxitem.percent}
							hsnbasedtaxes.push(obj)
						}
					})
					}
				})
			// console.log("Hsn based",hsnbasedtaxes)
			
			this.$store.commit("assignhsnbasedtaxes",hsnbasedtaxes)
			this.$store.commit("assigntotalnetprice",this.totalnetprice)
			if(typeof this.discount!='undefined'){
				let amtafterdiscount = parseFloat(this.totalnetprice) - parseFloat(this.discount)
				this.$store.commit("assigntotalnetpriceafterdiscount", amtafterdiscount)
			}
			this.$store.commit("assignamountwithouttax",this.amountwt)
			}
		}
	},
	methods:{
		increment(){
			this.counter = this.counter+1;
			return this.counter
		},
		selectcustomer(){
			this.$store.commit('assignviewno',2)
		},
		addbatchcodemodal(){
			let batchcodeobject = {productid:'',batchcode:'',expirydate:''}
			this.$store.commit('addbatchcodenexpiary',batchcodeobject);
			this.$modal.show("addbatchcodemodal")
		}
	}
}
</script>
<style scoped>
table>thead>th{
	text-align: center;
	font-size: 14px;
}
table{
	font-size: 14px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.form-cascade-control {
	width: 65%;
	padding: 4px;
}
</style>